import React from 'react';
import './PackageInformationStandardStyles.css'
import { MdCurrencyExchange, MdSupportAgent } from "react-icons/md";
import { IoAirplaneOutline } from "react-icons/io5";
import { FaClinicMedical, FaPassport, FaRegHospital, FaSimCard } from "react-icons/fa";
import { IoPeople } from "react-icons/io5";
import { TbEmergencyBed } from "react-icons/tb";
import { MdBabyChangingStation } from "react-icons/md";
import { FaRegAddressCard } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaPlus, FaUserDoctor } from "react-icons/fa6";
import { PiBuildingApartment } from "react-icons/pi";

interface StandardPackageProps {
    isEmbeddedInPremium: boolean;
}

const PackageInformationStandard: React.FC<StandardPackageProps> = (props) => {
    return (
        <div className='expandedStandardPackage' style={{
            marginBottom: props.isEmbeddedInPremium ? "20px" : "0px",
            paddingBottom: props.isEmbeddedInPremium ? "30px" : "0px",
            backgroundColor: props.isEmbeddedInPremium ? "#4C6249" : "inherit"
        }}>
            <div className='standardPackageHeading' style={{ color: props.isEmbeddedInPremium ? "#FFEAEA" : "var(--verde)" }}><MdSupportAgent className='standardPackageHeadingIcon' /> Информационная поддержка с самого первого дня, независимо от местонахождения</div>
            <div style={{ color: props.isEmbeddedInPremium ? "white" : "inherit" }} className='package-item-text'>Ещё перед вылетом мы с командой будем консультировать Вас по всем интересующим вопросам и будем оставаться на связи до момента вылета</div>

            <div className='standardPackageHeading' style={{ color: props.isEmbeddedInPremium ? "#FFEAEA" : "var(--verde)" }}><IoAirplaneOutline className='standardPackageHeadingIcon' />Встреча в аэропорту </div>
            <div style={{ color: props.isEmbeddedInPremium ? "white" : "inherit" }} className='package-item-text'>Встретим в аэропорту с водителем, предоставим переходники для розеток, карту Sube и другие необходимые вещи</div>

            <div className='standardPackageHeading' style={{ color: props.isEmbeddedInPremium ? "#FFEAEA" : "var(--verde)" }}><FaSimCard className='standardPackageHeadingIcon' />Оформление сим-карты</div>

            <div className='standardPackageHeading' style={{ color: props.isEmbeddedInPremium ? "#FFEAEA" : "var(--verde)" }}><MdCurrencyExchange className='standardPackageHeadingIcon' />Помощь в обмене USD по выгодному курсу</div>

            <div className='standardPackageHeading' style={{ color: props.isEmbeddedInPremium ? "#FFEAEA" : "var(--verde)" }}><PiBuildingApartment className='standardPackageHeadingIcon' />Помощь в подборе жилья в Буэнос-Айресе и подписании договора аренды</div>

            <div className='standardPackageHeading' style={{ color: props.isEmbeddedInPremium ? "#FFEAEA" : "var(--verde)" }}><FaUserDoctor className='standardPackageHeadingIcon' />Знакомство с врачом и акушеркой</div>

            <div className='standardPackageHeading' style={{ color: props.isEmbeddedInPremium ? "#FFEAEA" : "var(--verde)" }}><FaRegHospital className='standardPackageHeadingIcon' />Организация экскурсий в клиники</div>
            <div style={{ color: props.isEmbeddedInPremium ? "white" : "inherit" }} className='package-item-text'>Посмотрим клиники, которые вас заинтересуют</div>

            <div className='standardPackageHeading' style={{ color: props.isEmbeddedInPremium ? "#FFEAEA" : "var(--verde)" }}><FaClinicMedical className='standardPackageHeadingIcon' />Сопровождение в банк стволовых клеток, помощь в подписании договора</div>

            <div className='standardPackageHeading' style={{ color: props.isEmbeddedInPremium ? "#FFEAEA" : "var(--verde)" }}><IoPeople className='standardPackageHeadingIcon' />Сопровождение переводчика по медицинским вопросам до и после родов</div>
            <div style={{ color: props.isEmbeddedInPremium ? "white" : "inherit" }} className='package-item-text'>Сопровождение к врачам и на анализы и два визита после родов (один для мамы и один к неонатологу)</div>

            <div className='standardPackageHeading' style={{ color: props.isEmbeddedInPremium ? "#FFEAEA" : "var(--verde)" }}><TbEmergencyBed className='standardPackageHeadingIcon' />Сопровождение на родах</div>
            <div style={{ color: props.isEmbeddedInPremium ? "white" : "inherit" }} className='package-item-text'>Буду с Вами от самого начала родов и до перевода в послеродовую палату</div>

            <div className='standardPackageHeading' style={{ color: props.isEmbeddedInPremium ? "#FFEAEA" : "var(--verde)" }}><MdBabyChangingStation className='standardPackageHeadingIcon' />Выписка из роддома</div>
            <div style={{ color: props.isEmbeddedInPremium ? "white" : "inherit" }} className='package-item-text'>В день выписки встретим Вас с цветами, поможем подать документы на свидетельство о рождении</div>

            <div className='standardPackageHeading' style={{ color: props.isEmbeddedInPremium ? "#FFEAEA" : "var(--verde)" }}><FaRegAddressCard className='standardPackageHeadingIcon' />Получение аргентинских документов на ребенка</div>
            <div style={{ color: props.isEmbeddedInPremium ? "white" : "inherit" }} className='package-item-text'>Оформим Свидетельство о рождении, DNI и паспорт для малыша. {!props.isEmbeddedInPremium && <strong>Возможно срочное оформление документов</strong>}</div>

            <div className='standardPackageHeading' style={{ color: props.isEmbeddedInPremium ? "#FFEAEA" : "var(--verde)" }}><IoDocumentTextOutline className='standardPackageHeadingIcon' />Апостилирование свидетельства о рождении
            </div>

            <div className='standardPackageHeading' style={{ color: props.isEmbeddedInPremium ? "#FFEAEA" : "var(--verde)" }}><FaPassport className='standardPackageHeadingIcon' />Консультация по документам для получения DNI и гражданства</div>

            <hr />
            <div className='package-item-text' style={{ color: "black" }}><strong>! Все госпошлины, визиты к врачам, анализы оплачиваются клиентами и не входят в стоимость сопровождения</strong></div>

            {props.isEmbeddedInPremium && <div className='plusIconContainer'><FaPlus className="premiumPackagePlusIcon" /></div>}
        </div>
    );
};

export default PackageInformationStandard;