import React from 'react';
import './PackageInformationStandardStyles.css'
import { MdSupportAgent } from "react-icons/md";
import { IoAirplaneOutline } from "react-icons/io5";
import { FaHospitalUser, FaRegHospital } from "react-icons/fa";
import { IoPeople } from "react-icons/io5";
import { TbEmergencyBed } from "react-icons/tb";
import { MdBabyChangingStation } from "react-icons/md";
import { FaRegAddressCard } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaPlus, FaUserDoctor } from "react-icons/fa6";

const PackageInformationBeforeBirth: React.FC = () => {
    return (
        <div className='expandedStandardPackage' style={{
            marginBottom: "0px",
            paddingBottom: "0px",
            backgroundColor: "inherit"
        }}>
            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><MdSupportAgent className='standardPackageHeadingIcon' /> Информационная поддержка</div>
            <div style={{ color: "inherit" }} className='package-item-text'>Мы с командой будем консультировать Вас по всем интересующим вопросам</div>

            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><FaUserDoctor className='standardPackageHeadingIcon' />Знакомство с врачом и акушеркой</div>

            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><FaRegHospital className='standardPackageHeadingIcon' />Организация экскурсий в клиники</div>
            <div style={{ color: "inherit" }} className='package-item-text'>Посмотрим клиники, которые вас заинтересуют</div>

            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><IoPeople className='standardPackageHeadingIcon' />Сопровождение переводчика по медицинским вопросам до и после родов</div>
            <div style={{ color: "inherit" }} className='package-item-text'>Сопровождение к врачам (ЭКГ + КТГ + УЗИ) и на анализы <strong>до родов</strong> и два визита <strong>после родов</strong> (один для мамы и один к неонатологу)</div>

            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><TbEmergencyBed className='standardPackageHeadingIcon' />Сопровождение на родах</div>
            <div style={{ color: "inherit" }} className='package-item-text'>Буду с Вами с первых схваток и до перевода в послеродовую палату</div>

            <hr />
            <div className='package-item-text' style={{ color: "black" }}><strong>! Все госпошлины, визиты к врачам, анализы оплачиваются клиентами и не входят в стоимость сопровождения</strong></div>

        </div>
    );
};

export default PackageInformationBeforeBirth;