import React from 'react';
import './PackageInformationStandardStyles.css'
import { MdSupportAgent } from "react-icons/md";
import { IoAirplaneOutline } from "react-icons/io5";
import { FaRegHospital } from "react-icons/fa";
import { IoPeople } from "react-icons/io5";
import { TbEmergencyBed } from "react-icons/tb";
import { MdBabyChangingStation } from "react-icons/md";
import { FaRegAddressCard } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaPlus, FaUserDoctor } from "react-icons/fa6";

const PackageInformationJustBirth: React.FC = () => {
    return (
        <div className='expandedStandardPackage' style={{
            marginBottom: "0px",
            paddingBottom: "0px",
            backgroundColor: "inherit"
        }}>
            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><TbEmergencyBed className='standardPackageHeadingIcon' />Сопровождение на родах</div>
            <div style={{ color: "inherit" }} className='package-item-text'>Буду с Вами с первых схваток и до перевода в послеродовую палату</div>

        </div>
    );
};

export default PackageInformationJustBirth;