export const prcValues = [
    "3000",
    "5000",
    "10000",
    "1000",
    "1500",
    "300",
    "500",
    "500"
];

export default prcValues;