import React from 'react';
import './PackageInformationStandardStyles.css'
import { MdSupportAgent } from "react-icons/md";
import { IoAirplaneOutline } from "react-icons/io5";
import { FaRegHospital } from "react-icons/fa";
import { IoPeople } from "react-icons/io5";
import { TbEmergencyBed } from "react-icons/tb";
import { MdBabyChangingStation } from "react-icons/md";
import { FaRegAddressCard } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaPlus, FaUserDoctor } from "react-icons/fa6";

const PackageInformationAfterBirth: React.FC = () => {
    return (
        <div className='expandedStandardPackage' style={{
            marginBottom: "0px",
            paddingBottom: "0px",
            backgroundColor: "inherit"
        }}>

            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><TbEmergencyBed className='standardPackageHeadingIcon' />Сопровождение на родах</div>
            <div style={{ color: "inherit" }} className='package-item-text'>Буду с Вами от самого начала родов и до перевода в послеродовую палату</div>

            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><MdBabyChangingStation className='standardPackageHeadingIcon' />Выписка из роддома</div>
            <div style={{ color: "inherit" }} className='package-item-text'>В день выписки встречу Вас, и поможем подать документы на свидетельство о рождении</div>

            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><IoPeople className='standardPackageHeadingIcon' />Сопровождение переводчика по медицинским вопросам после родов</div>
            <div style={{ color: "inherit" }} className='package-item-text'>Сопровождение на два визита (один для мамы и один к неонатологу) к врачам <strong>после родов</strong></div>

            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><FaRegAddressCard className='standardPackageHeadingIcon' />Получение аргентинских документов на ребенка</div>
            <div style={{ color: "inherit" }} className='package-item-text'>Оформим Свидетельство о рождении, DNI и паспорт для малыша. <strong>Возможно срочное оформление документов</strong></div>

            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><IoDocumentTextOutline className='standardPackageHeadingIcon' />Апостилирование свидетельства о рождении
            </div>

            <hr />
            <div className='package-item-text' style={{ color: "black" }}><strong>! Все госпошлины, визиты к врачам, анализы оплачиваются клиентами и не входят в стоимость сопровождения</strong></div>

        </div>
    );
};

export default PackageInformationAfterBirth;